import 'virtual:svg-icons-register';
import 'unfonts.css';
import '@babel/polyfill';
import Swiper from 'swiper';
import 'whatwg-fetch';
import fetchJsonp from 'fetch-jsonp';
import '@/css/app.pcss';
import 'swiper/css';

// App main
const main = async () => {
    await import('lazysizes');
    await import('lazysizes/plugins/bgset/ls.bgset');
};

const scrollHelper = {
    easeInOut: function (currentTime, start, change, duration) {
        currentTime /= duration / 2;
        if (currentTime < 1) {
            return (change / 2) * currentTime * currentTime + start;
        }
        currentTime -= 1;
        return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
    },

    scrollTo: function (
        destination,
        duration = 200,
        easing = 'easeInOutQuad',
        callback = () => {}
    ) {
        const easings = {
            linear(t) {
                return t;
            },
            easeInQuad(t) {
                return t * t;
            },
            easeOutQuad(t) {
                return t * (2 - t);
            },
            easeInOutQuad(t) {
                return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            },
            easeInCubic(t) {
                return t * t * t;
            },
            easeOutCubic(t) {
                return --t * t * t + 1;
            },
            easeInOutCubic(t) {
                return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
            },
            easeInQuart(t) {
                return t * t * t * t;
            },
            easeOutQuart(t) {
                return 1 - --t * t * t * t;
            },
            easeInOutQuart(t) {
                return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
            },
            easeInQuint(t) {
                return t * t * t * t * t;
            },
            easeOutQuint(t) {
                return 1 + --t * t * t * t * t;
            },
            easeInOutQuint(t) {
                return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
            },
        };

        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        const documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );
        const windowHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.getElementsByTagName('body')[0].clientHeight;
        const destinationOffset =
            typeof destination === 'number' ? destination : destination.offsetTop;
        const destinationOffsetToScroll = Math.round(
            documentHeight - destinationOffset < windowHeight
                ? documentHeight - windowHeight
                : destinationOffset
        );

        function scroll() {
            const now = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min(1, (now - startTime) / duration);
            const timeFunction = easings[easing](time);
            window.scroll(0, Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start));

            if (window.pageYOffset === destinationOffsetToScroll) {
                if (callback) {
                    callback();
                }
                return;
            }

            requestAnimationFrame(scroll);
        }
        scroll();
    },
};

const parseHTML = function (str) {
    const tmp = document.implementation.createHTMLDocument('tmp');
    tmp.body.innerHTML = str;
    return tmp.body.children;
};

const _si = function (selector, context: HTMLElement | Document = document, returnNull = false) {
    const tmp = context.querySelector(selector);
    return tmp ? tmp : returnNull ? null : _c('div');
};
const _s = function (selector, context: HTMLElement | Document = document) {
    return Array.apply(null, context.querySelectorAll(selector));
};

const _c = function (elm) {
    return document.createElement(elm);
};

const _ael = function (sel, ev, callback) {
    document.addEventListener(ev, (e) => {
        if (e.target === document) {
            return;
        }
        const that = e.target.closest(sel);
        if (that) {
            callback(that, e);
        }
    });
};

interface Sticky {
    menu: HTMLElement;
    top: number;
    flag: boolean;
    sections: { top: number; li: HTMLElement }[];
    sectionOffset: number;
}

// Execute async function
main().then((value) => {
    const body = document.body,
        main = _si('main'),
        mainMenu = _si('.js--main-menu'),
        mainNav = _si('.js--nav');

    let lightboxSwiper,
        window_width = window.innerWidth,
        window_height = window.innerHeight,
        sticky: Sticky = {
            menu: _si('.js--sticky-menu', document, true),
            top: -1,
            flag: false,
            sections: [],
            sectionOffset: 150,
        },
        mainMenuFlag = true,
        scrollTop = window.pageYOffset,
        oldScrollTop = 0,
        swipersInited = false,
        postersInited = false;

    if ('ontouchstart' in document) {
        body.classList.remove('no-touch');
    }

    function render() {
        requestAnimationFrame(render);
        if (scrollTop !== oldScrollTop) {
            if (!mainMenuFlag && oldScrollTop > scrollTop) {
                mainMenuFlag = true;
                mainMenu.style.top = 0;
                mainMenu.style.marginTop = '-110px';
                setTimeout(() => {
                    mainMenu.style.transition = 'margin .3s ease-out';
                    mainMenu.style.marginTop = '0';
                    // TODO: Make this better
                    if (sticky.menu && sticky.flag) {
                        sticky.menu.style.transition = 'margin .3s ease-out';
                        sticky.menu.style.marginTop = '110px';
                    }
                }, 200);
                mainMenu.classList.add('fixed');
                mainMenu.classList.remove('absolute');
            } else if (mainMenuFlag && oldScrollTop < scrollTop) {
                mainMenuFlag = false;
                mainMenu.classList.remove('fixed');
                mainMenu.style.top = Math.max(oldScrollTop, 0) + 'px';
                mainMenu.classList.add('absolute');
                if (sticky.menu) {
                    sticky.menu.style.marginTop = '0';
                }
            }

            if (sticky.menu) {
                if (!sticky.flag && scrollTop > sticky.top) {
                    if (sticky.top === -1) {
                        initStickyHeader();
                    }
                    sticky.menu.classList.remove('absolute', 'text-lg');
                    sticky.menu.classList.add('fixed', 'py-6');
                    sticky.flag = true;
                }
                if (sticky.flag && scrollTop < sticky.top) {
                    sticky.menu.classList.add('absolute', 'text-lg');
                    sticky.menu.classList.remove('fixed', 'py-6');
                    sticky.flag = false;
                    sticky.menu.style.marginTop = '0';
                    _si('.text-orange', sticky.menu).classList.remove('text-orange');
                }

                if (sticky.flag) {
                    for (var i = sticky.sections.length; i--; ) {
                        if (scrollTop >= sticky.sections[i].top) {
                            _si('.text-orange', sticky.menu).classList.remove('text-orange');
                            sticky.sections[i].li.classList.add('text-orange');
                            break;
                        }
                    }
                }
            }

            oldScrollTop = scrollTop;
        }
    }
    requestAnimationFrame(render);

    function initStickyHeader() {
        sticky.top = sticky.menu.getBoundingClientRect().top + scrollTop;
        _s('a', sticky.menu).forEach((elm) => {
            const id = elm.href.substr(elm.href.indexOf('#') + 1),
                section = _si('[data-id="' + id + '"]');
            sticky.sections.push({
                top: section.getBoundingClientRect().top + scrollTop - sticky.sectionOffset,
                li: elm.parentNode,
            });
        });
    }

    document.addEventListener('scroll', () => {
        scrollTop = window.pageYOffset;
    });

    const picSupport = !!window.HTMLPictureElement;
    if (!picSupport) {
        _s('picture').forEach((elm) => {
            const img = _si('img', elm);
            const src = _si('source:last-of-type', elm);
            img.src = src.getAttribute('data-srcset');
        });
    }
    document.addEventListener('lazybeforeunveil', () => {
        if ((!postersInited && _si('.js--poster'), document, true)) {
            initPosters();
        }
        if (swipersInited) {
            return;
        }
        swipersInited = true;
        _s('.swiper-container').forEach((elm, i) => {
            if (elm.classList.contains('lightbox-swiper-container')) {
                return;
            }
            if (elm.classList.contains('swiper-no-swiping')) {
                return;
            }
            const extraClass = 'swiper-' + i;
            elm.classList.add(extraClass);
            _s('.swiper-slide', elm).forEach((slide) => {
                const img = _si('img', slide);
                slide.style.width = img.width + 'px';
            });
            new Swiper('.' + extraClass, {
                spaceBetween: 34,
                slideToClickedSlide: true,
                slidesPerView: 'auto',
                loop: true,
                centeredSlides: true,
                navigation: {
                    nextEl: '.' + extraClass + ' .swiper-btn-next',
                    prevEl: '.' + extraClass + ' .swiper-btn-prev',
                },
            });
        });
    });

    mainMenu.addEventListener('transitionend', () => {
        mainMenu.style.transition = 'none';
    });

    function fitWithinBox(elm, parent, outerBox, heightBound) {
        if (!elm) {
            return 9999;
        }
        const upperH = outerBox.height * heightBound,
            upperW = outerBox.width - outerBox.width * 0.2,
            debug = false;

        if (debug) {
            var upperdiv = document.createElement('div');
            upperdiv.style.width = upperW + 'px';
            upperdiv.style.height = upperH + 'px';
            upperdiv.className =
                'test' + (elm.parentNode.tagName.toLowerCase() == 'h3' ? ' test2' : '');
            parent.appendChild(upperdiv);
        }

        let size = 100;

        for (size; size--; ) {
            parent.style.fontSize = size + 'px';
            parent.style.lineHeight = size + 6 + 'px';
            var box = elm.getBoundingClientRect();
            var height = box.height;
            var width = box.width;
            if (debug) {
                console.group();
                console.log('size', size);
                console.log('real', height, width);
                console.log('upper', upperH, upperW);
                console.groupEnd();
            }
            if (height <= upperH && width <= upperW) {
                break;
            }
        }

        return size < 0 ? 100 : size;
    }

    function initPosters() {
        const topBound = 0.26,
            bottomBound = 0.3;
        _s('.js--poster').forEach((elm) => {
            if (elm.classList.contains('js--poster-frontpage')) {
                const box = elm.getBoundingClientRect();
                _si('img', elm).style.height = box.height + 'px';
                elm.style.width = (box.height * 5) / 7 + 'px';
                elm.style.transition = 'opacity .3s ease';
            }

            const outerBox = elm.getBoundingClientRect(),
                h2 = _si('h2 span', elm, true),
                h3 = _si('h3 span', elm, true),
                footer = _si('footer', elm);

            h2.style.transition = 'none';
            h2.style.opacity = 0;
            h3.style.transition = 'none';
            h3.style.opacity = 0;

            const h2size = fitWithinBox(h2, elm, outerBox, topBound);
            const h3size = fitWithinBox(h3, elm, outerBox, bottomBound);

            const mainSize = Math.min(h2size, h3size);
            elm.style.fontSize = mainSize + 'px';
            elm.style.lineHeight = mainSize + 6 + 'px';
            const footerSize = outerBox.height * 0.045;
            footer.style.fontSize = footerSize + 'px';
            footer.style.lineHeight = footerSize + 6 + 'px';

            if (mainSize * 0.8 <= footerSize && window['vigeland'].loggedIn) {
                if (h2size < h3size) {
                    alert(h2.innerText + ' har for mange tegn i tittelen');
                    h2.style.color = 'red';
                } else {
                    alert(h3.innerText + ' har for mange tegn i undertittelen');
                    h3.style.color = 'red';
                }
            }
            if (elm.classList.contains('js--poster-frontpage')) {
                elm.classList.remove('opacity-0');
            }
            setTimeout(function () {
                h2.style.transition = 'opacity .3s ease';
                h2.style.opacity = 1;
                h3.style.transition = 'opacity .3s ease';
                h3.style.opacity = 1;
                footer.style.transition = 'opacity .3s ease';
                footer.style.opacity = 1;
            }, 500);
            postersInited = true;
        });
    }

    setTimeout(() => {
        initPosters();
    }, 2000);

    let rsTO;
    window.addEventListener('resize', () => {
        clearTimeout(rsTO);
        if (window_width !== window.innerWidth) {
            window_width = window.innerWidth;
            window_height = window.innerHeight;
            rsTO = setTimeout(initPosters, 100);
        }
    });

    // KLIKK OG SÅNT, DAVØTTUUUUUUU
    _ael('.js--nav li span', 'click', (elm) => {
        elm.nextElementSibling.classList.toggle('hidden');
        elm.nextElementSibling.classList.toggle('flex');
    });

    _ael('.js--nav-toggle', 'click', () => {
        mainNav.classList.add('flex');
        mainNav.classList.remove('hidden');
    });

    _ael('.js--nav button', 'click', () => {
        mainNav.classList.remove('flex');
        mainNav.classList.add('hidden');
    });

    _ael('.js--expandable button', 'click', (elm) => {
        const parent = elm.parentNode;
        const content = _si('.js--content', parent);
        if (content.classList.contains('hidden')) {
            content.classList.remove('hidden');
            _si('.js--closed', elm).classList.add('hidden');
            _si('.js--open', elm).classList.remove('hidden');
        } else {
            content.classList.add('hidden');
            _si('.js--closed', elm).classList.remove('hidden');
            _si('.js--open', elm).classList.add('hidden');
        }
    });

    _ael('.js--play', 'click', (elm, e) => {
        e.preventDefault();
        const rx =
            /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        const r = elm.href.match(rx);
        const id = r[1];
        let yturl =
            'https://www.youtube.com/embed/{id}?autoplay=1&controls=1&rel=0&showinfo=0&modestbranding=1&iv_load_policy=3';
        const iframe = _si('iframe', elm.parentNode);
        iframe.src = yturl.replace('{id}', id);
        elm.classList.add('hidden');
    });

    _ael('.js--open-gallery', 'click', (elm, e) => {
        e.preventDefault();
        const galleryname = elm.getAttribute('data-gallery'),
            anchors = _s('[data-gallery="' + galleryname + '"]'),
            wrapper = _si('[data-wrapper="' + galleryname + '"]'),
            container = wrapper.parentNode,
            index = anchors.indexOf(elm);

        wrapper.innerHTML = '';
        _si('.js--current', container).innerHTML = index + 1;
        anchors.forEach((anchor) => {
            const slide = _si('.swiper-slide', anchor),
                clone = slide.cloneNode(true);
            clone.classList.remove('hidden');
            clone.classList.add('flex');
            wrapper.appendChild(clone);
        });

        container.classList.add('swiper-container');
        wrapper.closest('.js--lightbox').classList.remove('hidden');

        lightboxSwiper = new Swiper('.' + galleryname, {
            spaceBetween: 30,
            initialSlide: index,
            navigation: {
                nextEl: '.' + galleryname + ' .swiper-next',
                prevEl: '.' + galleryname + ' .swiper-prev',
            },
            on: {
                slideChange: () => {
                    if (lightboxSwiper) {
                        _si('.js--current', container).innerText = lightboxSwiper.activeIndex + 1;
                    }
                },
            },
        });
    });

    document.addEventListener('keydown', (e) => {
        if (e.key.toLowerCase() === 'escape') {
            _si('.js--close-lightbox').click();
        }
    });

    _ael('.js--close-lightbox', 'click', (elm) => {
        lightboxSwiper.destroy();
        lightboxSwiper = null;
        elm.parentNode.classList.add('hidden');
    });

    _ael('.js--search-reset', 'click', (elm) => {
        elm.previousElementSibling.value = '';
        elm.previousElementSibling.focus();
    });

    _ael('.js--sticky-menu li', 'click', (elm, e) => {
        e.preventDefault();
        if (sticky.top === -1) {
            initStickyHeader();
        }
        const index = _s('li', elm.parentNode).indexOf(elm);
        scrollHelper.scrollTo(sticky.sections[index].top + 50, 300);
    });

    _ael('.js--scrolltop', 'click', (elm, e) => {
        e.preventDefault();
        scrollHelper.scrollTo(0, 300);
    });

    _ael('input', 'input', (elm) => {
        elm.classList.remove('error');
        elm.parentNode.classList.remove('error');
    });

    const apiKey = 'ebcbf4071d808656bd883de590e05e68-us1';
    _ael('.js--newsletter', 'submit', (elm, e) => {
        e.preventDefault();
        let errors = '';
        const errorMsg = _si('.js--error-msg');

        const email = _si('input[type="email"]');
        if (email.value === '') {
            email.classList.add('error');
            errors = 'Du må fylle inn e-post<br>';
        }

        const checkbox = _si('input[type="checkbox"]');
        if (!checkbox.checked) {
            checkbox.parentNode.classList.add('error');
            errors += 'Du må gi oss samtykke til å sende deg e-post';
        }

        if (errors) {
            errorMsg.innerHTML = errors;
            errorMsg.classList.remove('hidden');
            return;
        }
        const url =
            elm.action.replace('/post?', '/post-json?') + '&c=?&EMAIL=' + _si('input', elm).value;
        // fetch('https://username.us2.list-manage.com/subscribe/post-json?u=yourKey&id=yourID&c=?&EMAIL=random@email.com&name=francis&age=12')
        fetchJsonp(url, {
            jsonpCallback: 'c',
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.result === 'error') {
                    errorMsg.innerHTML = data.msg;
                    errorMsg.classList.remove('hidden');
                } else {
                    const initial = _si('.js--initial-headline', elm),
                        success = _si('.js--success', elm),
                        fields = _si('.js--fields', elm);

                    initial.classList.add('hidden');
                    initial.classList.remove('block');
                    success.classList.add('block');
                    success.classList.remove('hidden');
                    fields.classList.add('invisible');
                }
            });
    });

    // FREEFORM OVERRIDES
    //@ts-ignore
    window.renderFormSuccess = function (form) {
        const success = _si('.js--success', form),
            fields = _si('.js--fields', form);

        success.classList.remove('hidden');
        fields.classList.add('hidden');
    };

    //@ts-ignore
    window.renderFormErrors = (errors, form) => {
        //trenger ikke noen feilmelding for hele skjema
    };

    //@ts-ignore
    window.renderErrors = (errors, form) => {
        for (var key in errors) {
            if (!errors.hasOwnProperty(key) || !key) {
                continue;
            }

            var messages = errors[key];
            var errorsList = document.createElement('ul');
            errorsList.className = 'list-reset text-red mt-2 px-7-5';

            for (var messageIndex = 0; messageIndex < messages.length; messageIndex++) {
                var message = messages[messageIndex];
                var listItem = document.createElement('li');
                listItem.appendChild(document.createTextNode(message));
                errorsList.appendChild(listItem);
            }

            var inputList = form.querySelectorAll('*[name=' + key + "], *[name='" + key + "[]']");
            for (var inputIndex = 0; inputIndex < inputList.length; inputIndex++) {
                var input = inputList[inputIndex];

                input.classList.add('error');
                input.parentElement.appendChild(errorsList);
            }
        }
    };

    document.documentElement.style.opacity = '1';
});
